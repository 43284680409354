import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class EncryptionService {
  // string key length must me 16 character (HEX Char)
  private key = CryptoJS.enc.Utf8.parse(environment.encryption.key);
  private iv = CryptoJS.enc.Utf8.parse(environment.encryption.iv);

  /**
   * * Encrypt Using AES256
   */
  encryptUsingAES256(request: { [key: string]: any } | any) {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(request), this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    const response = encrypted.toString();
    return response;
  }

  /**
   * * Decrypt Using AES256
   * JSON Parse date before use
   */
  decryptUsingAES256(request: string) {
    const response = CryptoJS.AES.decrypt(request, this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
    return JSON.parse(response);
  }

  /**
   * * Decrypt Using AES256
   * JSON Parse date before use
   */
  decryptUsingAES256String(request: string) {
    const response = CryptoJS.AES.decrypt(request, this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
    return response;
  }
}
